import SwiperCore, { Swiper, Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Autoplay, Navigation, Pagination]);

function setTabindexOnInteractiveElements (parentElement: Element, index: number): void {
    for (const element of parentElement.querySelectorAll<HTMLElement>('a, button, video')) {
        element.tabIndex = index;
    }
}

function deactivateElement (element: Element): void {
    setTabindexOnInteractiveElements(element, -1);
    element.setAttribute('aria-hidden', 'true');
}

function activateElement (element: Element): void {
    setTabindexOnInteractiveElements(element, 0);
    element.removeAttribute('aria-hidden');
}

// assumes there is only one slide visible
function makeInvisibleSlidesInert (swiper: Swiper): void {
    for (let i = 0; i < swiper.slides.length; i++) {
        if (i !== swiper.activeIndex) {
            setTabindexOnInteractiveElements(swiper.slides[i], -1);
            swiper.slides[i].setAttribute('aria-hidden', 'true');
        }
    }
}

export function createSlider (selector: string): Swiper {
    const pagination = document.querySelector(selector + ' .swiper-pagination');
    let previousActiveIndex = -1;

    return new Swiper(selector, {
        preloadImages: false,
        loop: true,
        autoplay: {
            delay: 6000, // in milliseconds
            pauseOnMouseEnter: true,
            disableOnInteraction: true
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className): string {
                const ariaCurrent = (index === 0) ? ' aria-current="true"' : '';
                const dataIndex = ` data-index="${index + 1}"`;
                return `<li class="${className}"${dataIndex}><button type="button"${ariaCurrent}><span class="sr-only">Gehe zu Nachricht ${index + 1}</span></button></li>`;
            }
        },
        runCallbacksOnInit: false,
        on: {
            init: function (swiper: Swiper): void {
                makeInvisibleSlidesInert(swiper);

                swiper.el.querySelector<HTMLElement>('.swiper-button-pause')?.addEventListener('click', function (this: HTMLElement, event: MouseEvent) {
                    event.preventDefault();
                    if (this.getAttribute('data-state') === 'play') {
                        swiper.autoplay.stop();
                        swiper.wrapperEl.setAttribute('aria-live', 'polite');
                        this.setAttribute('data-state', 'pause');
                    } else {
                        swiper.wrapperEl.setAttribute('aria-live', 'off');
                        swiper.autoplay.start();
                        this.setAttribute('data-state', 'play');
                    }
                });
            },
            slideChangeTransitionStart: function (swiper: Swiper): void {
                if (previousActiveIndex > 0) {
                    deactivateElement(swiper.slides[swiper.previousIndex]);
                    pagination?.querySelector('button[aria-current]')?.removeAttribute('aria-current');
                }
            },
            slideChangeTransitionEnd: function (swiper: Swiper): void {
                activateElement(swiper.slides[swiper.activeIndex]);
                pagination?.querySelector(`li[data-index="${swiper.activeIndex}"] > button`)?.setAttribute('aria-current', 'true');
                previousActiveIndex = swiper.activeIndex;
            }
        }
    });
}
