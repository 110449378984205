/* eslint-disable import/no-relative-parent-imports */
// eslint-disable-next-line import/no-unassigned-import
import '../scss/home.scss';
/* eslint-enable import/no-relative-parent-imports */

// eslint-disable-next-line import/no-unassigned-import
import './modules/juicer';
import { createSlider } from './modules/slider';

function workOnDom (): void {
    createSlider('.big-slider');
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}
