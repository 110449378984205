declare global {
    interface Window {
        processJuicerDom: () => void
    }
}

function processJuicerDom (): void {
    document.querySelectorAll<HTMLElement>('.j-read-more').forEach((link) => {
        link.insertAdjacentText('beforebegin', '…');
        link.innerHTML = 'mehr erfahren<span class="sr-only">, öffnet eine externe URL in einem neuen Fenster</span>';
    });

    document.querySelectorAll<HTMLElement>('.j-paginate').forEach((link) => {
        link.innerText = 'mehr anzeigen';
    });

    const header = document.querySelector('h1.referral');
    if (header) {
        header.outerHTML = '<p class="referral">' + header.innerHTML + '</p>';
    }
}

window.processJuicerDom = processJuicerDom;

export {};
